import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Influencers from '../components/influencers'

import { PeopleT } from "../services/seotemplate"

const People = ({ data }) => {
  const title = PeopleT.title
  const description = PeopleT.description

  const schema = 
  {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": title,
    "description": description
  }

    return (
    <Layout>
      <SEO title={title} description={description} schemaMarkup={schema}/>
      <Influencers influencers={data.allInfluencer.edges}/>
    </Layout>)
}


export const query = graphql`
  {
    allInfluencer(sort: {fields: display_name}) {
      edges {
        node {
          user_screen_name
          id
          display_name
          description
          user_name
          profile_image
          num_of_books
        }
      }
    }
  }
`

export default People