import React from 'react'
import { CardColumns } from 'react-bootstrap'
import Influencer from '../influencer'
import './style.css'

function Influencers(props){
    return(
        <CardColumns className="peoplecards">
          {props.influencers.map(influencer => (
          <Influencer key={influencer.node.id} influencer={influencer.node}
          screenName={influencer.node.user_screen_name} 
          userName={influencer.node.display_name? influencer.node.display_name : influencer.node.user_name} 
          profileText={influencer.node.description} profileImage={influencer.node.profile_image}
          />
        ))}
      </CardColumns>
    )
}

export default Influencers;