import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'gatsby'
import Image from 'react-bootstrap/Image'
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { getInfluencerDetails } from '../../services/influencerdetails'
import './style.css'

function Influencer({influencer}){
    const { name, hyphen_name, profile_image } = getInfluencerDetails(influencer)
    //const lowercase_screen_name = user_screen_name.toLowercase()
    //Can use image from twitter user object incase not using avatars.io
    
    return(
        <Card className="no-border">
            <Card.Body>
                <Card.Text>
                  <Link to={"/books/" + hyphen_name}>
                    <LazyLoadComponent><Image src={profile_image} className="photo" alt={name} roundedCircle /></LazyLoadComponent>
                  </Link>
                </Card.Text>
                <Card.Text>
                  <Link to={"/books/" + hyphen_name} className="name">
                    {name}
                  </Link>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Influencer;